import React from 'react'
import styles from './index.module.scss'
import * as actions from '../../actions'
import { Link } from 'react-router-dom'
import { getAvatarUrl } from '../../api'

function ModalViewNumber({ dispatch, user }) {
  return (
    <div className={styles.ModalViewNumber}>
      <div
        className={styles.modalBg}
        onClick={() => {
          dispatch(actions.isModalMobilePhone(null))
        }}
      />
      <div className={styles.modalContainer}>
        <div className={styles.modalInner}>
          <div
            className={styles.closeContainer}
            onClick={() => {
              dispatch(actions.isModalMobilePhone(null))
            }}
          >
            <i className={styles.closeIcon}></i>
          </div>
          <div className={styles.modalBoxInner}>
            <p className={styles.userNameIn}>
              {user.firstName} {user.lastName}
            </p>
            <figure className={styles.userFigure}>
              <a href={`/user/${user.id}`} className={styles.linkTouser}>
                <img
                  src={getAvatarUrl(user.avatar)}
                  width={72}
                  height={72}
                  alt="Елена С."
                  className={styles.avatar}
                />
              </a>
            </figure>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ModalViewNumber
